@tailwind base;
@tailwind components;
@tailwind utilities;




body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.b-red {
  border: 1px solid red;
}

.b-blue {
  border: 1px solid blue;
}